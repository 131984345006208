import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

// Components
const Users = Loader(lazy(() => import('src/content/management/Users')));
const Vehicles = Loader(lazy(() => import('src/content/management/Vehicles')));
const CreateVehicle = Loader(
  lazy(() => import('src/content/management/Vehicles/create'))
);
const EditVehicle = Loader(
  lazy(() => import('src/content/management/Vehicles/edit'))
);
const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Leads = Loader(lazy(() => import('src/content/management/Leads')));
const CreateLead = Loader(
  lazy(() => import('src/content/management/Leads/create'))
);
const Sales = Loader(lazy(() => import('src/content/management/Sales')));
const CreateSale = Loader(
  lazy(() => import('src/content/management/Sales/create'))
);

const managementRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'single',
        children: [
          {
            path: '',
            element: <Navigate to="1" replace />
          },
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      }
    ]
  },
  {
    path: 'vehicles',
    children: [
      {
        path: 'list',
        element: <Vehicles />
      },
      {
        path: 'create',
        element: <CreateVehicle />
      },
      {
        path: 'edit',
        children: [
          {
            path: '',
            element: <Navigate to="1" replace />
          },
          {
            path: ':vehicleId',
            element: <EditVehicle />
          }
        ]
      }
    ]
  },
  {
    path: 'leads',
    children: [
      {
        path: 'list',
        element: <Leads />
      },
      {
        path: 'create',
        element: <CreateLead />
      }
    ]
  },
  {
    path: 'sales',
    children: [
      {
        path: 'list',
        element: <Sales />
      },
      {
        path: 'create',
        element: <CreateSale />
      }
    ]
  }
];

export default managementRoutes;
