import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

// Components
const AdminDashboard = Loader(
  lazy(() => import('src/content/home/AdminDashboard'))
);
const Users = Loader(lazy(() => import('src/content/management/Users')));
const Vehicles = Loader(lazy(() => import('src/content/management/Vehicles')));
const CreateVehicle = Loader(
  lazy(() => import('src/content/management/Vehicles/create'))
);

const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Leads = Loader(lazy(() => import('src/content/management/Leads')));
const CreateLead = Loader(
  lazy(() => import('src/content/management/Leads/create'))
);

const homeRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />
  },
  {
    path: 'admin-dashboard',
    element: <AdminDashboard />
  }
];

export default homeRoutes;
