import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Zoom } from '@mui/material';
import { CssBaseline } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import { SnackbarCloseButton } from './components/SnackbarClose';
import ThemeProvider from './theme/ThemeProvider';
import router from 'src/router';
import AppInit from './components/AppInit';
import { ApiProvider } from './contexts/ApiContext';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={50}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          hideIconVariant
          autoHideDuration={4000}
          TransitionComponent={Zoom}
          action={(key) => <SnackbarCloseButton snackbarKey={key} />}
        >
          <ApiProvider>
            <CssBaseline />
            {auth.isInitialized ? content : <AppInit />}
          </ApiProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
