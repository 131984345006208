import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';

import baseRoutes from './base';
import managementRoutes from './management';
import homeRoutes from './home';

const router: RouteObject[] = [
  // {
  //   path: 'account',
  //   children: accountRoutes
  // },
  // {
  //   path: '',
  //   element: <BaseLayout />,
  //   children: baseRoutes
  // },
  // Documentation

  // Boxed Sidebar Layout

  // Accent Sidebar Layout

  // Collapsed Sidebar Layout

  // Bottom Navigation Layout

  // Top Navigation Layout

  // Accent Header Layout

  // Extended Sidebar Layout

  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      ...baseRoutes,
      {
        path: 'home',
        children: homeRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  }
];

export default router;
