import type { ReactNode } from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HailIcon from '@mui/icons-material/Hail';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Home',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/home/admin-dashboard'
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AccountBoxIcon,
        link: '/extended-sidebar/management/users',
        items: [
          {
            name: 'List',
            link: 'management/users/list'
          },
          {
            name: 'User Profile',
            link: 'management/users/single'
          }
        ]
      },
      {
        name: 'Vehicles',
        icon: DirectionsCarIcon,
        link: '/extended-sidebar/management/vehicles',
        items: [
          {
            name: 'List',
            link: 'management/vehicles/list'
          },
          {
            name: 'Create',
            link: 'management/vehicles/create'
          }
        ]
      },
      {
        name: 'Leads',
        icon: HailIcon,
        link: '/extended-sidebar/management/leads',
        items: [
          {
            name: 'List',
            link: 'management/leads/list'
          },
          {
            name: 'Create',
            link: 'management/leads/create'
          }
        ]
      },
      {
        name: 'Sales',
        icon: PointOfSaleIcon,
        // link: '/extended-sidebar/management/sales',
        items: [
          {
            name: 'List',
            link: 'management/sales/list'
          },
          {
            name: 'Create',
            link: 'management/sales/create'
          }
        ]
      }
    ]
  }
];

export default menuItems;
