const esJSON = {
  Blueprints: 'Planos',
  Home: 'Inicio',
  Dashboard: 'Tablero',
  'Extended Sidebar': 'Barra lateral extendida',
  'Accent Sidebar': 'Barra lateral de acento',
  'Accent Header': 'Encabezado de acento',
  'Boxed Sidebar': 'Barra lateral en caja',
  'Collapsed Sidebar': 'Barra lateral contraída',
  'Bottom Navigation': 'Navegación inferior',
  'Top Navigation': 'Navegación superior',
  'Lead Developer': 'Desarrollador principal',
  'Mega menu': 'Mega menú',
  Doctors: 'Doctores',
  Reports: 'Informes',
  'Custom dashboard built using the included components':
    'Panel de control personalizado construido con los componentes incluidos',
  Hospital: 'Hospital',
  Export: 'Exportar',
  'Last year': 'El año pasado',
  Expenses: 'Gastos',
  Products: 'Productos',
  Statistics: 'Estadísticas',
  General: 'General',
  Automation: 'Automatización',
  'Data Display': 'Visualización de datos',
  Calendar: 'Calendario',
  Mailbox: 'Buzón',
  Dashboards: 'Cuadros de mando',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Applications: 'Aplicaciones',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  Management: 'Administración',
  Users: 'Usuarios',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create Product': 'Crear producto',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  Login: 'Acceso',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Welcome: 'Bienvenida',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Sign in': 'Iniciar sesión',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  dashboards: 'cuadros de mando',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',
  'Fill in the fields below to sign into your account.':
    'Rellene los campos a continuación para iniciar sesión en su cuenta.',
  'Lost password?': '¿Perdiste tu contraseña?',
  'Email address': 'Dirección de correo electrónico',
  Password: 'Contraseña',
  'Users Management': 'Administración de Usuarios',
  'All aspects related to the app users can be managed from this page':
    'Desde aquí podrás administrar todas las características de los usuarios',
  'All aspects related to the vehicles can be managed from this page':
    'Desde aquí podrás administrar todas las características de los vehículos',
  'Create user': 'Crear usuario',
  'All users': 'Todos',
  Vehicles: 'Vehículos',
  Vehicle: 'Vehículo',
  Create: 'Crear',
  'Create vehicle': 'Crear vehículo',
  Price: 'Precio',
  Interested: 'Interesados',
  Category: 'Categoría',
  Actions: 'Acciones',
  List: 'Lista',
  'Add new vehicle': 'Añadir nuevo vehículo',
  'Fill in the fields below to create a new vehicle':
    'Rellene los campos a continuación para crear un nuevo vehículo',
  'Go back to the list': 'Volver a la lista',
  Name: 'Nombre',
  'Additional Information': 'Información Adicional',
  Title: 'Título',
  'Regular price': 'Precio regular',
  'Regular price here ...': 'Coloque el precio regular...',
  'Sale price': 'Precio en oferta',
  'Sale price here ...': 'Coloque el precio en oferta...',
  'License plate': 'Placa',
  'License plate here ...': 'Coloque la placa...',
  'Last digit': 'Último dígito',
  'Last digit here ...': 'Coloque el último dígito...',
  'Chassis number': 'Número de chasis',
  'Chassis number here ...': 'Coloque el número de chasis...',
  'Doors number': 'Número de puertas',
  'Doors number here ...': 'Coloque el número de puertas...',
  Publish: 'Publicar',
  Visible: 'Visible',
  Supplier: 'Proveedor',
  'Publish now': 'Publicar ahora',
  Categories: 'Categorías',
  'Add category': 'Añadir categoría',
  'Vehicle images': 'Imágenes del vehículo',
  'Main image': 'Imagen principal',
  'Secondary images': 'Imágenes secundarias',
  'All aspects related to the leads can be managed from this page':
    'Todas las características de los leads se pueden administrar desde esta página',
  'Create lead': 'Crear lead',
  Lead: 'Lead',
  Budget: 'Presupuesto',
  'Registered By': 'Registrado por',
  'Add new lead': 'Añadir nuevo lead',
  'Fill in the fields below to create a new lead':
    'Rellene los campos a continuación para crear un nuevo lead',
  'General Information': 'Información general',
  'Name here ...': 'Coloque el nombre...',
  'Last Name': 'Apellido',
  'Last name here ...': 'Coloque el apellido...',
  Document: 'Cédula',
  'Document here ...': 'Coloque la cédula...',
  Phone: 'Teléfono',
  'Phone here ...': 'Coloque el teléfono...',
  'Budget here ...': 'Coloque el presupuesto...',
  Email: 'Correo electrónico',
  'Email here ...': 'Coloque el correo electrónico...',
  Address: 'Dirección',
  'Address here ...': 'Coloque la dirección...',
  "All the statistics you'll ever need":
    'Todas las estadísticas que necesitarás',
  Sales: 'Ventas',
  Balance: 'Balance',
  Progress: 'Progreso',
  'Best sellers this month': 'Mejores vendedores este mes',
  View: 'Ver',
  'Weekly sales': 'Ventas semanales',
  'Here you have an sales overview for the current week':
    'Aquí tienes una visión general de las ventas de la semana actual',
  "SUV's": 'SUV',
  Pickups: 'Camionetas',
  at: 'en',
  Description: 'Descripción',
  Brand: 'Marca',
  'Add brand': 'Añadir marca',
  'Add new brand': 'Añadir nueva marca',
  'Fill in the fields below to create and add a new brand':
    'Rellene los campos a continuación para crear y agregar un nueva marca',
  'Add new category': 'Añadir nueva categoría',
  'Fill in the fields below to create and add a new category':
    'Rellene los campos a continuación para crear y agregar un nueva categoría',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  'The name field is required': 'El campo nombre es obligatorio',
  'The last name field is required': 'El campo apellido es obligatorio',
  'The document field is required': 'El campo cédula es obligatorio',
  'The phone field is required': 'El campo teléfono es obligatorio',
  'The email field is required': 'El campo correo es obligatorio',
  'The mobile field is required': 'El campo teléfono es obligatorio',
  'The address field is required': 'El campo dirección es obligatorio',
  'Saved successfully!': 'Guardado con éxito!',
  'Updated successfully!': 'Actualizado con éxito!',
  Mileage: 'Kilometraje',
  'Mileage here ...': 'Coloque el kilometraje...',
  Year: 'Año',
  'Year here ...': 'Coloque el año...',
  'The budget field must be greater than 0':
    'El presupuesto debe ser mayor a 0',
  'The budget field must be a number': 'El presupuesto debe ser un número',
  'All aspects related to the sales can be managed from this page':
    'Desde aquí podrás administrar todas las características de las ventas',
  'Client Information': 'Información del cliente',
  'Create sale': 'Crear venta',
  'Available vehicles': 'Vehículos disponibles',
  'Choose a vehicle to add to the sale':
    'Elija un vehículo para agregar a la venta',
  'Available clients': 'Vehículos clientes',
  'Choose a client to add to the sale':
    'Elija un cliente para agregar a la venta',
  'Select a vehicle': 'Seleccione un vehículo',
  'Add new sale': 'Añadir nueva venta',
  'Fill in the fields below to create a new sale':
    'Rellene los campos a continuación para crear una nueva venta',
  'Invoice date': 'Fecha de factura',
  Seller: 'Vendedor'
};

export default esJSON;
