import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

import { config } from './config';

const axiosInt = axios.create({ baseURL: config.server.apiUrl });

// axiosInt.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || 'There is an error!'
//     )
// );

const axiosTest = axios.create();

export const mock = new AxiosMockAdapter(axiosTest, { delayResponse: 0 });
// export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

export default axiosInt;
